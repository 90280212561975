export const syrupData = [
  {
    name: 'Belgium Cookie Crème',
  },
  {
    name: 'Crème De Menth',
  },
  {
    name: 'Marshmallow Sauce',
  },
  {
    name: 'Nutella',
  },
  {
    name: 'Natures Honey',
  },
  {
    name: 'Butterscotch',
  },
  {
    name: 'Coffee Syrup',
  },
  {
    name: 'White Chocolate',
  },
  {
    name: 'Raspberry Sauce',
  },
  {
    name: 'Wild Cherry Sauce',
  },
  {
    name: 'Hot Fudge',
  },
  {
    name: 'Hot Caramel',
  },
  {
    name: 'Chocolate syrup',
  },
  {
    name: 'Peanut butter syrup',
  },
  {
    name: 'Strawberry syrup',
  },
  {
    name: 'Raspberry syrup',
  },
];

export default {
  syrupData,
};
